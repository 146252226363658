import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component';

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Tuesday 17th August 2021 we will be making the following changes to our mortgage product
      range:</p>
    <p><strong>Summary of changes</strong></p>
    <p>We are introducing further differential pricing which includes specific products for remortgage customers, first
      time buyers and home movers for our residential range. These are in addition to our ranges for existing mortgage
      customers switching and existing mortgage customers borrowing more.</p>
    <p>The rates for each of the various buyer types can be found on the Mortgage Rate Sheet in the relevant range
      dependant on whether the customer is an existing mortgage customer or new business customer.</p>
    <p>We are also introducing further differential pricing for our Buy to Let range which will include specific
      products for landlords completing purchases or remortgaging. These are in addition to our ranges for existing
      mortgage customers switching and existing mortgage customers borrowing more.</p>
    <p>There are no other changes to any of our interest rates or fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 17th August 2021 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate
      sheet</a></p>

  </NewsArticle>
);

export default Article;
